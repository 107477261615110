.button-container {
	position: relative;
	border: 2px solid var(--yellow);
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0 10px 0;
	cursor: pointer;
	height: 56px;
	width: 200px;
}

.button-container::before,
.button-container::after {
	content: '';
	position: absolute;
	border-radius: inherit;
	border: inherit;
	transition: all 0.5s;
	height: 20px;
	width: 20px;
}

.button-container::before {
	top: -10px;
	left: -10px;
	border-width: 2px 0 0 2px;
}

.button-container::after {
	bottom: -10px;
	right: -10px;
	border-width: 0 2px 2px 0;
}

.button-container:hover::before,
.button-container:hover::after {
	width: calc(100% + 27px);
	height: calc(100% + 27px);
}

.button-container .text {
	color: var(--yellow) !important;
	font-size: 14px;
	padding: 10px;
	transition: all 0.5s ease;
	font-family: var(--regular);
}

.button-container:hover .text {
	transform: scale(1.1);
}

@media screen and (max-width: 992px) {
	.button-container {
		border: 2px solid var(--yellow);
		height: 46px;
		width: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px 0 10px 0;
		cursor: pointer;
	}

	.button-container .text {
		color: var(--yellow);
		font-size: 14px;
		padding: 10px;
	}
}

@media screen and (max-width: 768px) {
	.button-container {
		border: 2px solid var(--yellow);
		height: 46px;
		width: 180px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px 0 10px 0;
		cursor: pointer;
	}

	.button-container .text {
		color: var(--yellow);
		font-size: 14px;
		padding: 8px;
	}
}

@media screen and (max-width: 480px) {
	.button-container {
		border: 2px solid var(--yellow);
		height: 32px;
		width: 180px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px 0 10px 0;
		cursor: pointer;
	}

	.button-container .text {
		color: var(--yellow);
		font-size: 14px;
		padding: 5px;
	}
}

@media screen and (max-width: 320px) {
	.button-container {
		border: 2px solid var(--yellow);
		height: 32px;
		width: 150px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px 0 10px 0;
		cursor: pointer;
	}

	.button-container .text {
		color: var(--yellow);
		font-size: 14px;
		padding: 5px;
	}
}

.form-container {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 997;
	background-color: var(--gray);
	transition: all 0.5s ease-in-out;
}

.form-container .container {
	position: absolute;
	top: 10vw !important;
	left: 15vw;
	transition: all 0.5s ease-in-out;
}

.form-container .modal {
	width: 70vw;
	height: 70vh;
	border-radius: 10px;
	border: 2px solid var(--gray);
	background-color: var(--dark);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: all 0.5s ease-in-out;
}

.form-container .CloseIcon {
	position: absolute;
	top: 20px;
	right: 20px;
	transition: all 0.5s ease-in-out;
}

.form-container .CloseIcon:hover {
	cursor: pointer;
	transform: scale(1.3);
}

.form-container .heading {
	font-size: 34px;
	color: var(--light);
	width: 40vw;
	text-align: center;
}

.form-container form .textarea {
	height: 80px;
	width: 100%;
}

.form-container form .field input,
.form-container form .textarea textarea {
	height: 45px;
	width: 40vw;
	border: 2px solid var(--yellow);
	outline: none;
	padding: 0 15px;
	font-size: 15px;
	font-family: 'Urbanist';
	transition: all 0.5s ease;
	margin-bottom: 15px;
}

.form-container form .field input:focus,
.form-container form .textarea textarea:focus {
	border-color: var(--light);
}

.form-container form .textarea textarea {
	padding-top: 10px;
	resize: none;
}

.form-container form .button-area {
	display: flex;
	align-items: center;
}

.form-container .button-area button {
	position: relative;
	border: 2px solid var(--yellow);
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0 10px 0;
	cursor: pointer;
	height: 46px;
	width: 100%;
	font-family: 'Urbanist';
	font-size: 14px;
	background-color: var(--dark);
	color: var(--light);
}

.form-container .button-area button::before,
.form-container .button-area button::after {
	content: '';
	position: absolute;
	border-radius: inherit;
	border: inherit;
	transition: all 0.5s;
	height: 20px;
	width: 50px;
}

.form-container .button-area button::before {
	top: -10px;
	left: -10px;
	border-width: 2px 0 0 2px;
}

.form-container .button-area button::after {
	bottom: -10px;
	right: -10px;
	border-width: 0 2px 2px 0;
}

.form-container .button-area button:hover::before,
.form-container .button-area button:hover::after {
	width: calc(100% + 27px);
	height: calc(100% + 27px);
}

@media screen and (max-width: 992px) {
	.form-container .container {
		position: absolute;
		top: 15vw !important;
		left: 10vw;
	}

	.form-container .modal {
		width: 75vw;
		height: 70vh;
	}

	.form-container .heading {
		font-size: 26px;
		color: var(--light);
		width: 55vw;
		text-align: center;
	}

	.form-container form .field input,
	.form-container form .textarea textarea {
		height: 45px;
		width: 65vw;
	}
}

@media screen and (max-width: 768px) {
	.form-container .container {
		position: absolute;
		top: 15vw !important;
		left: 5vw;
	}

	.form-container .modal {
		width: 85vw;
		height: 70vh;
	}

	.form-container .heading {
		font-size: 24px;
		color: var(--light);
		width: 55vw;
		text-align: center;
	}

	.form-container form .field input,
	.form-container form .textarea textarea {
		height: 45px;
		width: 65vw;
	}
}

@media screen and (max-width: 480px) {
	.form-container .container {
		position: absolute;
		top: 20vw !important;
		left: 3vw;
	}

	.form-container .modal {
		width: 90vw;
		height: 70vh;
	}

	.form-container .heading {
		font-size: 20px;
		color: var(--light);
		width: 60vw;
		text-align: center;
	}

	.form-container form .field input,
	.form-container form .textarea textarea {
		height: 45px;
		width: 75vw;
	}
}

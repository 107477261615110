.navbar {
	display: flex;
	background-color: var(--dark);
	height: 100px;
	z-index: 995;
	top: 0;
	border-bottom: 2px solid var(--gray);
	position: fixed;
	width: 100%;
}

.navbar .container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.navbar .logoContainer {
	position: absolute;
	width: 160px;
	height: 90px;
	left: 95px;
	top: 10px;
	overflow: hidden;
}

.navbar .logo {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.navbar .menuIcon {
	display: none;
}

.navbar .navMenu {
	display: grid;
	grid-template-columns: repeat(5, auto);
	list-style: none;
	align-items: center;
	justify-content: center;
}

.navbar .nav-item {
	height: 50px;
	margin-left: 16px;
	margin-right: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar .navLinks {
	color: var(--light);
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 8px 16px;
	height: 100%;
	font-size: 15px;
	cursor: pointer;
	font-family: var(--regular);
	letter-spacing: 1px;
}

.navbar .navLinks:hover {
	text-decoration: none;
	border-bottom: 3px solid var(--light);
	transition: all 0.2s ease-out;
	padding-bottom: 5px;
	color: var(--light);
}

.nav-links-active {
	text-decoration: none;
	border-bottom: 3px solid var(--yellow);
	transition: all 0.2s ease-out;
	padding-bottom: 5px;
	color: var(--yellow);
}

.navbar .themeContainer {
	position: absolute;
	width: 180px;
	height: 100px;
	right: 100px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	color: var(--light);
	font-size: 16px;
}

.navbar .themeContainer p {
	font-family: var(--regular);
	letter-spacing: 1px;
}

.navbar .nav-item-theme {
	display: none;
}

.navbar .ball {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: var(--dark);
	transition: all 0.2s ease-in-out;
}

.navbar .themeSwitch {
	display: flex;
	width: 65px;
	height: 31px;
	background-color: none;
	justify-content: space-evenly;
	align-items: center;
	padding: auto 5px;
	margin: auto 10px;
	cursor: pointer;
	border: 2px solid var(--light);
	border-radius: 50px;
}

@media screen and (max-width: 1400px) {
	.navbar .logoContainer {
		left: 60px;
	}

	.navbar .themeContainer {
		width: 180px;
		height: 60px;
	}
}

@media screen and (max-width: 1200px) {
	.navbar .logoContainer {
		left: 40px;
	}

	.navbar .themeContainer {
		width: 160px;
		height: 40px;
	}

	.navbar .themeContainer p {
		display: none;
	}
}

@media screen and (max-width: 992px) {
	.navbar .logoContainer {
		left: 20px;
	}

	.navbar .navMenu {
		position: absolute;
		top: 80px;
		display: flex;
		flex-direction: column;
		justify-items: flex-start;
		align-items: center;
		width: 100vw;
		height: 100vh;
		left: -100%;
		opacity: 0;
		transition: all 0.5s ease;
	}

	.navbar .navMenu.active {
		background-color: var(--dark);
		left: -50px;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
	}

	.navbar .nav-item {
		width: 100%;
		margin: 20px 0;
	}

	.navbar .navLinks {
		text-align: center;
		width: 100%;
		justify-content: center;
		align-items: center;
	}

	.navbar .navLinks:hover {
		background-color: var(--light);
		color: var(--dark);
		border-radius: 0;
	}

	.navbar .themeContainer {
		display: none;
	}

	.navbar .nav-item-theme {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		margin-top: 10px;
	}

	.navbar .menuIcon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 30px);
		cursor: pointer;
		height: 32px;
		width: 32px;
	}

	.nav-links-active {
		background-color: var(--yellow);
		color: var(--dark);
		border-radius: 0;
	}
}

@media screen and (max-width: 768px) {
	.navbar {
		height: 90px;
	}

	.navbar .navMenu {
		position: absolute;
		top: 75px;
		display: flex;
		flex-direction: column;
		width: 100vw;
		height: 100vh;
		left: -100%;
		opacity: 0;
		transition: all 0.5s ease;
	}

	.navbar .logoContainer {
		position: absolute;
		width: 85px;
		height: 85px;
		left: 10px;
		top: 5px;
		overflow: hidden;
		cursor: pointer;
	}

	.navbar .menuIcon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 30px);
		cursor: pointer;
		height: 32px;
		width: 32px;
	}

	.navbar .nav-item-theme {
		display: flex !important;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
	}

	.navbar .nav-item-theme p {
		font-family: var(--regular);
	}
}

@media screen and (max-width: 480px) {
	.navbar {
		height: 75px;
	}

	.navbar .navMenu {
		position: absolute;
		top: 60px;
		display: flex;
		flex-direction: column;
		width: 100vw;
		height: 100vh;
		left: -100%;
		opacity: 0;
		transition: all 0.5s ease;
	}

	.navbar .logoContainer {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 5px;
		top: 5px;
		overflow: hidden;
		cursor: pointer;
	}

	.navbar .menuIcon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 20px);
		cursor: pointer;
		height: 28px;
		width: 28px;
	}

	.navbar .nav-item-theme {
		display: flex !important;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
	}
}

@media screen and (max-width: 320px) {
	.navbar {
		height: 75px;
	}

	.navbar .navMenu {
		position: absolute;
		top: 60px;
		display: flex;
		flex-direction: column;
		width: 100vw;
		height: 100vh;
		left: -100%;
		opacity: 0;
		transition: all 0.5s ease;
	}

	.navbar .logoContainer {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 5px;
		top: 5px;
		overflow: hidden;
		cursor: pointer;
	}

	.navbar .menuIcon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 20px);
		cursor: pointer;
		height: 26px;
		width: 26px;
	}

	.navbar .nav-item-theme {
		display: flex !important;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
	}
}

.project-card-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--gray);
	border-radius: 10px;
	margin: 5px;
	overflow: hidden;
	width: 27vw;
	height: 27vw;
	border: 2px solid var(--gray);
	position: relative;
}

.project-card-container .design {
	width: 27vw;
	height: 27vw;
	overflow: hidden;
	transition: all 0.5s ease;
}

.project-card-container .image {
	object-fit: cover;
	width: 100%;
	width: 100%;
	transition: all 0.5s ease;
}

.project-card-container .design:hover .image {
	transform: scale(1.1);
}

.project-card-container .icon-container {
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	transition: all 0.5s ease;
	z-index: 3;
	border-radius: 5px;
	cursor: pointer;
}

.project-card-container .icon-container:hover {
	background-color: #1d1e2a80;
}

.project-card-container .icon-container .icon {
	width: 25px;
	height: 25px;
	color: #1d1e2a;
	transition: all 0.5s ease;
}

.project-card-container .icon-container:hover .icon {
	transform: scale(1.1);
	color: #f4f6fc;
}

@media screen and (max-width: 1200px) {
	.project-card-container {
		margin: 0 10px;
		width: 27vw;
		height: 27vw;
	}

	.project-card-container .design {
		width: 27vw;
		height: 27vw;
	}
}

@media screen and (max-width: 992px) {
	.project-card-container {
		margin: 0 10px;
		width: 45vw;
		height: 45vw;
	}

	.project-card-container .design {
		width: 45vw;
		height: 45vw;
	}
}

@media screen and (max-width: 768px) {
	.project-card-container {
		margin: 0 5px;
		width: 80vw;
		height: 80vw;
	}

	.project-card-container .design {
		width: 80vw;
		height: 80vw;
	}
}

@media screen and (max-width: 480px) {
	.project-card-container {
		margin: 0 5px;
		width: 90vw;
		height: 90vw;
	}

	.project-card-container .design {
		width: 90vw;
		height: 90vw;
	}
}

@media screen and (max-width: 320px) {
	.project-card-container {
		margin: 0 5px;
		width: 90vw;
		height: 90vw;
	}

	.project-card-container .design {
		width: 90vw;
		height: 90vw;
	}
}

.main {
	display: flex;
	margin: 110px 0 auto 0;
	position: relative;
	background-color: var(--dark);
	height: fit-content;
	justify-content: center;
	align-items: center;
	z-index: 1;
	padding-top: 20px;
	padding-bottom: 60px;
}

.main .textContainer {
	padding-left: 10px;
	width: 35%;
}

.main .text {
	color: var(--yellow);
	font-size: 16px;
	margin: 20px 0;
	font-family: var(--regular);
	letter-spacing: 1px;
	line-height: 1.5;
}

.main .bold {
	color: var(--light);
	font-size: 68px;
	margin: 20px 0;
	font-family: var(--bold);
	letter-spacing: 1px;
}

.main .secondText {
	color: var(--light);
	font-size: 16px;
	margin: 20px 0;
	font-family: var(--regular);
	letter-spacing: 1px;
	line-height: 1.5;
}

.main .imageContainer {
	margin-left: 30px;
	width: 40%;
	height: 65vh;
	overflow: hidden;
	border-radius: 5px;
}

.main .image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.main .statsContainer {
	display: flex;
	flex-direction: column;
	background-color: var(--gray);
	height: fit-content;
	justify-content: center;
	align-items: center;
	width: 15%;
}

.main .stats {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 150px;
}

.main .number {
	color: var(--light);
	font-size: 48px;
	margin: 0;
}

.main .label {
	color: var(--light);
	font-size: 16px;
	margin: 0;
	text-align: center;
	font-family: var(--regular);
	letter-spacing: 1px;
	line-height: 1.5;
}

@media screen and (max-width: 1400px) {
	.main .textContainer {
		width: 40%;
	}

	.main .bold {
		font-size: 62px;
	}

	.main .imageContainer {
		margin-left: 30px;
		width: 35%;
		height: 65vh;
	}

	.main .statsContainer {
		width: 10%;
	}

	.main .stats {
		width: 140px;
		height: 140px;
	}
}

@media screen and (max-width: 1200px) {
	.main .textContainer {
		width: 40%;
	}

	.main .bold {
		font-size: 58px;
	}

	.main .imageContainer {
		margin-left: 30px;
		width: 40%;
		height: 65vh;
	}

	.main .statsContainer {
		width: 10%;
	}

	.main .stats {
		width: 100px;
		height: 100px;
	}
}

@media screen and (max-width: 992px) {
	.main {
		display: flex;
		margin: 10px 0;
		position: relative;
		flex-direction: column;
		background-color: var(--dark);
		height: 95vh;
		justify-content: center;
		align-items: center;
		z-index: 1;
	}

	.main .textContainer {
		padding-left: 0;
		width: 70%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.main .text {
		color: var(--yellow);
		font-size: 16px;
		margin: 20px 0;
		text-align: center;
	}

	.main .bold {
		color: var(--light);
		font-size: 58px;
		margin: 20px 0;
		text-align: center;
	}

	.main .secondText {
		color: var(--light);
		font-size: 16px;
		margin: 20px 0;
		text-align: center;
	}

	.main .imageContainer {
		display: none;
	}

	.main .statsContainer {
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		background-color: var(--gray);
		height: fit-content;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
	}

	.main .stats {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
	}

	.main .number {
		color: var(--light);
		font-size: 38px;
		margin: 0;
	}
}

@media screen and (max-width: 768px) {
	.main {
		display: flex;
		margin: 100px 0 10px 0;
		position: relative;
		flex-direction: column;
		background-color: var(--dark);
		padding-top: 30px;
		padding-bottom: 20px;
		height: fit-content;
		justify-content: center;
		align-items: center;
		z-index: 1;
	}

	.main .textContainer {
		padding-left: 0;
		width: 70vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.main .text {
		color: var(--yellow);
		font-size: 16px;
		margin: 15px 0;
		text-align: center;
	}

	.main .bold {
		color: var(--light);
		font-size: 48px;
		margin: 15px 0;
		text-align: center;
	}

	.main .secondText {
		color: var(--light);
		font-size: 16px;
		margin: 15px 0;
		text-align: center;
	}

	.main .imageContainer {
		display: none;
	}

	.main .statsContainer {
		margin-top: 15px;
		display: flex;
		flex-direction: row;
		background-color: var(--gray);
		height: fit-content;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
	}

	.main .stats {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
	}

	.main .number {
		color: var(--light);
		font-size: 28px;
		margin: 0;
	}
}

@media screen and (max-width: 480px) {
	.main {
		display: flex;
		margin: 100px 0 10px 0;
		position: relative;
		flex-direction: column;
		background-color: var(--dark);
		padding-top: 20px;
		padding-bottom: 15px;
		height: fit-content;
		justify-content: center;
		align-items: center;
		z-index: 1;
	}

	.main .textContainer {
		padding-left: 0;
		width: 90vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.main .text {
		color: var(--yellow);
		font-size: 16px;
		margin: 15px 0;
		text-align: center;
	}

	.main .bold {
		color: var(--light);
		font-size: 32px;
		margin: 15px 0;
		text-align: center;
	}

	.main .secondText {
		color: var(--light);
		font-size: 16px;
		margin: 15px 0;
		text-align: center;
	}

	.main .imageContainer {
		display: none;
	}

	.main .statsContainer {
		margin-top: 15px;
		display: flex;
		flex-direction: row;
		background-color: var(--gray);
		height: fit-content;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
	}

	.main .stats {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
	}

	.main .number {
		color: var(--light);
		font-size: 24px;
		margin: 0;
	}

	.main .label {
		color: var(--light);
		font-size: 14px;
		margin: 0;
		text-align: center;
	}
}

@media screen and (max-width: 320px) {
	.main {
		display: flex;
		margin: 100px 0 10px 0;
		position: relative;
		flex-direction: column;
		background-color: var(--dark);
		padding-top: 20px;
		padding-bottom: 15px;
		height: fit-content;
		justify-content: center;
		align-items: center;
		z-index: 1;
	}

	.main .textContainer {
		padding-left: 0;
		width: 95vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.main .text {
		color: var(--yellow);
		font-size: 16px;
		margin: 15px 0;
		text-align: center;
	}

	.main .bold {
		color: var(--light);
		font-size: 24px;
		margin: 15px 0;
		text-align: center;
	}

	.main .secondText {
		color: var(--light);
		font-size: 16px;
		margin: 15px 0;
		text-align: center;
	}

	.main .imageContainer {
		display: none;
	}

	.main .statsContainer {
		margin-top: 15px;
		display: flex;
		flex-direction: row;
		background-color: var(--gray);
		height: fit-content;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
	}

	.main .stats {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
	}

	.main .number {
		color: var(--light);
		font-size: 24px;
		margin: 0;
	}

	.main .label {
		color: var(--light);
		font-size: 14px;
		margin: 0;
		text-align: center;
	}
}

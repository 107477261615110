.services-container {
	display: flex;
	flex-direction: column;
	margin: 10px 0 auto 0;
	padding-top: 40px;
	position: relative;
	background-color: var(--gray);
	height: fit-content;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 60px;
}

.services-container .text {
	color: var(--yellow);
	font-size: 16px;
	font-family: var(--semibold);
	text-align: center;
	letter-spacing: 1px;
}

.services-container .bold {
	color: var(--light);
	font-size: 36px;
	margin: 30px 0;
	width: 40%;
	text-align: center;
	letter-spacing: 1px;
	line-height: 1.5;
	font-family: var(--regular);
}

.services-container .flex {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

@media screen and (max-width: 1200px) {
	.services-container .bold {
		color: var(--light);
		font-size: 36px;
		margin: 30px 0;
		width: 50%;
		text-align: center;
	}

	.services-container .flex {
		display: grid;
		grid-template-columns: repeat(4, auto);
		justify-content: space-evenly;
		align-items: center;
	}
}

@media screen and (max-width: 992px) {
	.services-container {
		display: flex;
		flex-direction: column;
		margin: 10px 0 auto 0;
		padding-top: 40px;
		position: relative;
		background-color: var(--gray);
		justify-content: flex-start;
		align-items: center;
	}

	.services-container .bold {
		color: var(--light);
		font-size: 36px;
		margin: 30px 0;
		width: 55%;
		text-align: center;
	}

	.services-container .flex {
		display: grid;
		grid-template-columns: repeat(2, auto);
		justify-content: space-evenly;
		align-items: center;
	}
}

@media screen and (max-width: 768px) {
	.services-container {
		display: flex;
		flex-direction: column;
		margin: 10px 0 auto 0;
		position: relative;
		background-color: var(--gray);
		padding-top: 30px;
		padding-bottom: 30px;
		height: fit-content;
		justify-content: flex-start;
		align-items: center;
	}

	.services-container .bold {
		color: var(--light);
		font-size: 32px;
		margin: 20px 0;
		width: 65%;
		text-align: center;
	}

	.services-container .flex {
		display: grid;
		grid-template-columns: repeat(2, auto);
		justify-content: space-evenly;
		align-items: center;
	}
}

@media screen and (max-width: 480px) {
	.services-container {
		display: flex;
		flex-direction: column;
		margin: 10px 0 20px 0;
		padding-top: 20px;
		position: relative;
		background-color: var(--gray);
		justify-content: flex-start;
		align-items: center;
		padding-bottom: 25px;
		height: fit-content;
	}

	.services-container .bold {
		color: var(--light);
		font-size: 24px;
		margin: 15px 0;
		width: 85%;
		text-align: center;
	}

	.services-container .text {
		color: var(--yellow);
		font-size: 14px;
		font-family: var(--semibold);
		text-align: center;
	}

	.services-container .flex {
		display: grid;
		grid-template-columns: repeat(1, auto);
		justify-content: space-evenly;
		align-items: center;
	}
}

@media screen and (max-width: 320px) {
	.services-container {
		padding-top: 20px;
		padding-bottom: 25px;
		height: fit-content;
	}

	.services-container .bold {
		color: var(--light);
		font-size: 20px;
		margin: 15px 0;
		width: 90%;
		text-align: center;
	}

	.services-container .flex {
		display: grid;
		grid-template-columns: repeat(1, auto);
		justify-content: space-evenly;
		align-items: center;
	}
}

.article-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--dark);
	height: fit-content;
	margin-top: 110px;
}

.article-container .content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 50vh;
	height: fit-content;
	width: 70vw;
}

.article-container .hide{
	display: none;
}

.article-container .content .commands {
	color: var(--yellow);
	font-family: var(--commandfont);
}

.article-container .content .link {
	color: var(--yellow);
	cursor: pointer;
	font-family: var(--commandfont);
}

.article-container .content .link:hover {
	text-decoration: underline;
}

.article-container .content .article-header {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 500px;
	width: 70vw;
	overflow: hidden;
	position: relative;
	background-image: url(../assets/images/dark-header.png);
	background-size: cover;
	background-position: center;
	background-color: var(--dark);
}

.article-container .content .article-header.light {
	background-image: url(../assets/images/light-header.png);
	background-size: cover;
	background-position: center;
	background-color: var(--dark);
}

.article-container .article-header .article-header-inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--dark50);
}

.article-container .article-header-inner h1 {
	color: var(--light);
	font-size: 40px;
	font-family: var(--regular);
	letter-spacing: 3px;
	text-align: center;
}

.article-container .article-header-inner p {
	color: var(--light);
	font-size: 14x;
	font-family: var(--regular);
	letter-spacing: 1px;
	text-align: center;
}

.article-container .article .about-author .author-image,
.article-container .article-header-inner .author-image {
	width: 70px;
	height: 70px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	margin: 10px 0;
}

.article-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.article-container .article-header-inner .tags {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 20px 0;
}

.article-container .article-header-inner .tags .tag {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--gray);
	color: var(--dark);
	font-size: 14px;
	font-family: var(--regular);
	padding: 5px 10px;
	margin-right: 10px;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.5s ease;
}

.article-container .article-header-inner .tags .tag:hover {
	background-color: var(--light);
	color: var(--dark);
}

.article-container .article-header-inner .tags .tag span {
	margin: 0 5px;
	color: var(--light);
	transition: all 0.5s ease;
}

.article-container .article-header-inner .tags .tag:hover span {
	color: var(--dark);
}

.article-container .article-header-inner .stats .lg{
	display: flex;
}

.article-container .article-header-inner .stats{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 50vw;
	margin: 20px 0;
	background-color: var(--gray);
}

.article-container .article-header-inner .stats .side{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: fit-content;
}

.article-container .article-header-inner .stats .stat{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 10px 0;
	cursor: pointer;
	margin: auto 20px;
}

.article-container .stats .stat span{
	font-family: var(--regular);
	font-size: 15px;
	color: var(--light);
	margin: auto 8px;
}

.article-container .article {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-height: 50vh;
	height: fit-content;
	width: 50vw;
	margin: 20px 0;
	margin-bottom: 100px;
}

.article-container .article .article-image {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
	width: 100%;
	overflow: hidden;
	border-radius:5px;
	border: 1px solid var(--grey);
}

.article-container .article h2 {
	color: var(--light);
	font-size: 20px;
	font-family: var(--regular);
	letter-spacing: 2px;
	text-align: justify;
}

.article-container .article p,
.article-container .article b,
.article-container .article li {
	color: var(--light);
	font-size: 15px;
	font-family: var(--regular);
	letter-spacing: 1px;
	text-align: justify;
	line-height: 1.5;
}

.article-container .article b {
	font-family: var(--semibold);
	color: var(--yellow);
}

.article-container .article ol {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: fit-content;
	width: 93%;
	margin: 20px 0;
}

.article-container .article .breakpoint {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 100%;
}

.article-container .article .underline {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2px;
	width: 100%;
	background-color: var(--gray);
}

.article-container .article .code {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	width: 100%;
	margin: 30px 0;
	border-radius: 10px;
	background-color: var(--code);
	border: 1px solid var(--gray);
	padding: 5px 10px;
}

.article-container .article .code .cm-theme {
	width: 100%;
}

.article-container .article .about-author {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: fit-content;
	margin: 30px 0;
	border: 1px solid var(--gray);
	border-radius: 10px;
	background-color: var(--gray);
	padding: 30px;
	width: 50vw;
}

.article-container .article .about-author-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: fit-content;
	width: 43vw;
}

.article-container .article .about-author-text h3 {
	color: var(--light);
	font-size: 20px;
	font-family: var(--regular);
	letter-spacing: 2px;
	text-align: justify;
	margin: 5px 0;
}

.article-container .article .about-author-text p {
	color: var(--light);
	font-size: 15px;
	font-family: var(--regular);
	letter-spacing: 1px;
	text-align: justify;
	line-height: 1.5;
}

.article-container .article .about-author-text span {
	color: var(--light);
	font-size: 15px;
	font-family: var(--semibold);
	letter-spacing: 1px;
	cursor: pointer;
	transition: all 0.5s ease;
}

.article-container .article .about-author-text span:hover {
	color: var(--yellow);
}

.article-container .related {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: fit-content;
	width: 50vw;
	margin: 30px 0;
}

.article-container .related h2,
.article-container .comments h2,
.article-container .notFound h2 {
	color: var(--light);
	font-size: 20px;
	font-family: var(--regular);
	letter-spacing: 2px;
	text-align: justify;
	margin: 5px 0;
}

.article-container .related ul {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: fit-content;
	width: 93%;
}

.article-container .related ul li {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: fit-content;
	width: 100%;
	margin: 10px 0;
	cursor: pointer;
	font-family: var(--regular);
	font-size: 15px;
	color: var(--light);
	transition: all 0.5s ease;
	letter-spacing: 1px;
	text-decoration: underline;
	padding: 5px 0;
}

.article-container .related ul li:hover {
	color: var(--yellow);
}

.article-container .comments,
.article-container .comments form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: fit-content;
	width: 100%;
	margin: 30px 0;
}

.article-container .comments form {
	width: 30vw;
	margin: 10px 0;
}

.article-container .comments .form-group {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: fit-content;
	width: 30vw;
	margin: 10px 0;
	position: relative;
}

.article-container .comments .form-group label {
	color: var(--light);
	font-size: 15px;
	font-family: var(--regular);
	letter-spacing: 1px;
	text-align: justify;
	margin: 10px 0;
}

.article-container .comments .form-group span {
	color: var(--light);
	font-size: 14px;
	font-family: var(--regular);
	letter-spacing: 1px;
	text-align: justify;
	margin-top: 5px;
}

.article-container .comments .form-group input,
.article-container .comments .form-group textarea {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 30px;
	width: 30vw;
	border: 1px solid var(--gray);
	border-radius: 10px;
	padding: 10px;
	background-color: var(--gray);
	color: var(--light);
	font-size: 15px;
	font-family: var(--regular);
	letter-spacing: 1px;
	text-align: justify;
	line-height: 1.5;
}

.article-container .comments .form-group input:focus,
.article-container .comments .form-group textarea:focus {
	outline: none;
}

.article-container .comments .form-group textarea {
	height: 50px;
}

.article-container .comments button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 55px;
	width: 31vw;
	border: 1px solid var(--gray);
	border-radius: 10px;
	padding: 10px;
	background-color: var(--yellow);
	color: var(--dark);
	font-size: 15px;
	font-family: var(--semibold);
	letter-spacing: 1px;
	text-align: justify;
	line-height: 1.5;
	cursor: pointer;
	transition: all 0.5s ease;
	margin: 20px 0;
}

.article-container .comments button:hover {
	background-color: var(--gray);
	color: var(--light);
}

.article-container .notFound {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 50vh;
	width: 100%;
}

.article-container .notFound p {
	color: var(--light);
	font-size: 15px;
	font-family: var(--regular);
	letter-spacing: 1px;
	text-align: justify;
	line-height: 1.5;
}

@media screen and (max-width: 1200px) {
	.article-container .content {
		width: 80vw;
	}

	.article-container .content .article-header {
		width: 80vw;
	}

	.article-container .article-header-inner h1 {
		font-size: 36px;
	}
	
	.article-container .article-header-inner .stats{
		width: 60vw;
	}

	.article-container .article {
		width: 60vw;
	}

	.article-container .article .about-author {
		width: 60vw;
	}

	.article-container .article .about-author-text {
		width: 50vw;
	}

	.article-container .comments form {
		width: 40vw;
	}

	.article-container .comments .form-group {
		width: 40vw;
	}

	.article-container .comments .form-group input,
	.article-container .comments .form-group textarea {
		width: 40vw;
	}

	.article-container .comments button {
		width: 42vw;
	}
}

@media screen and (max-width: 992px) {
	.article-container .content {
		width: 85vw;
	}

	.article-container .content .article-header {
		width: 85vw;
	}

	.article-container .article-header-inner h1 {
		font-size: 30px;
	}

	.article-container .article-header-inner .stats{
		width: 80vw;
	}

	.article-container .article .article-image {
		height: 400px;
		width: 100%;
	}

	.article-container .article {
		width: 80vw;
	}

	.article-container .related {
		width: 80vw;
	}

	.article-container .article .about-author {
		width: 80vw;
	}

	.article-container .article .about-author-text {
		width: 70vw;
	}

	.article-container .related h2,
	.article-container .comments h2,
	.article-container .notFound h2 {
		font-size: 20px;
	}

	.article-container .comments form {
		width: 50vw;
	}

	.article-container .comments .form-group {
		width: 50vw;
	}

	.article-container .comments .form-group input,
	.article-container .comments .form-group textarea {
		width: 50vw;
	}

	.article-container .comments button {
		width: 52vw;
	}
}

@media screen and (max-width: 768px) {
	.article-container .content {
		width: 95vw;
	}

	.article-container .content .article-header {
		width: 95vw;
	}

	.article-container .article-header-inner h1 {
		font-size: 28px;
	}

	.article-container .article-header-inner .tags {
		margin: 10px 0;
	}

	.article-container .article-header-inner .tags .tag {
		margin: 0 5px;
		padding: 3px 5px;
	}

	.article-container .article-header-inner .stats{
		width: 90vw;
	}

	.article-container .article-header-inner .stats .lg{
		display: none;
	}

	.article-container .article-header-inner .stats .side{
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.article-container .article-header-inner .stats{
		display: flex;
		flex-direction: row;
		justify-content: center;

	}

	.article-container .article .article-image {
		height: 300px;
		width: 100%;
	}

	.article-container .article {
		width: 90vw;
	}

	.article-container .article h2 {
		font-size: 20px;
	}

	.article-container .related {
		width: 90vw;
	}

	.article-container .related h2,
	.article-container .comments h2,
	.article-container .notFound h2 {
		font-size: 18px;
	}

	.article-container .article .about-author {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 85vw;
		padding: 20px 10px;
	}

	.article-container .article .about-author-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 80vw;
	}

	.article-container .article .about-author-text h3 {
		text-align: center !important;
	}

	.article-container .article .about-author-text p {
		text-align: center;
	}

	.article-container .comments form {
		width: 80vw;
	}

	.article-container .comments .form-group {
		width: 80vw;
	}

	.article-container .comments .form-group input,
	.article-container .comments .form-group textarea {
		width: 80vw;
	}

	.article-container .comments button {
		width: 83vw;
	}
}

@media screen and (max-width: 480px) {
	.article-container .article-header-inner h1 {
		font-size: 24px;
	}

	.article-container .article-header-inner .tags {
		margin: 10px 0;
	}

	.article-container .article-header-inner .tags .tag {
		font-size: 12px;
		margin: 0 5px;
		padding: 3px 5px;
	}

	.article-container .article-header-inner .stats{
		width: 90vw;
	}

	.article-container .article-header-inner .stats .lg{
		display: none;
	}

	.article-container .article-header-inner .stats .side{
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.article-container .article-header-inner .stats{
		display: flex;
		flex-direction: row;
		justify-content: center;
		
	}

	.article-container .article {
		width: 90vw;
	}

	.article-container .article .article-image {
		height: 200px;
		width: 100%;
		object-position: 0;
	}

	.article-container .article h2 {
		font-size: 18px;
		text-align: left;
	}

	.article-container .article ol {
		margin: 10px 0;
		width: 90%;
	}

	.article-container .related {
		width: 90vw;
	}

	.article-container .article p,
	.article-container .article b,
	.article-container .article li {
		text-align: left;
	}

	.article-container .related h2,
	.article-container .comments h2,
	.article-container .notFound h2 {
		font-size: 16px;
		text-align: left;
	}

	.article-container .related ul {
		width: 90%;
	}

	.article-container .article .about-author {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 85vw;
		padding: 20px 10px;
	}

	.article-container .article .about-author-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 80vw;
	}

	.article-container .article .about-author-text h3 {
		text-align: center;
	}

	.article-container .article .about-author-text p {
		text-align: center;
	}

	.article-container .comments form {
		width: 85vw;
	}

	.article-container .comments .form-group {
		width: 85vw;
	}

	.article-container .comments .form-group input,
	.article-container .comments .form-group textarea {
		width: 85vw;
	}

	.article-container .comments button {
		width: 90vw;
	}
}

@media screen and (max-width: 320px) {
	.article-container .article-header-inner h1 {
		font-size: 20px;
	}

	.article-container .article-header-inner .tags {
		margin: 10px 0;
	}

	.article-container .article-header-inner .tags .tag {
		font-size: 10px;
		margin: 0 5px;
		padding: 3px 5px;
	}

	.article-container .article-header-inner .stats{
		width: 90vw;
	}

	.article-container .article-header-inner .stats .lg{
		display: none;
	}

	.article-container .article-header-inner .stats{
		display: flex;
		flex-direction: row;
		justify-content: center;
		
	}

	.article-container .article-header-inner .stats .side{
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.article-container .article-header-inner .stats .stat{
		margin: auto 10px;
	}

	.article-container .article {
		width: 90vw;
	}

	.article-container .article h2 {
		font-size: 16px;
		text-align: left;
	}

	.article-container .article ol {
		margin: 10px 0;
		width: 90%;
	}

	.article-container .related {
		width: 90vw;
	}

	.article-container .article p,
	.article-container .article b,
	.article-container .article li {
		text-align: left;
	}

	.article-container .related h2,
	.article-container .comments h2,
	.article-container .notFound h2 {
		font-size: 14px;
		text-align: left;
	}

	.article-container .related ul {
		width: 90%;
	}

	.article-container .article .about-author {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 85vw;
		padding: 10px 5px;
	}

	.article-container .article .about-author-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 80vw;
	}

	.article-container .article .about-author-text h3 {
		text-align: center;
	}

	.article-container .article .about-author-text p {
		text-align: center;
	}
}

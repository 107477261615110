@font-face {
	font-family: 'Urbanist-Regular';
	src: url('./assets/fonts/Urbanist-Regular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Urbanist-Medium';
	src: url('./assets/fonts/Urbanist-Medium.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Urbanist-Semibold';
	src: url('./assets/fonts/Urbanist-SemiBold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Urbanist-Bold';
	src: url('./assets/fonts/Urbanist-Bold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'SohneMono';
	src: url('./assets/fonts/SohneMono.otf') format('truetype');
	font-display: swap;
}

html,
body {
	max-width: 100%;
	overflow-x: hidden;
}

body {
	font-family: 'Urbanist', sans-serif;
	font-size: 14px;
	color: var(--light);
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: var(--dark);
	margin: 0px;
}

html {
	scroll-behavior: smooth;
}

.dark-theme {
	--light: #f4f6fc;
	--dark: #1d1e2a;
	--yellow: #fad862;
	--gray: #f4f6fc10;
	--grey: #fad86230;
	--dark50: #1d1e2a80;
	--code: #1e1e1e;

	--regular: 'Urbanist-Regular', sans-serif;
	--medium: 'Urbanist-Medium', sans-serif;
	--semibold: 'Urbanist-Semibold', sans-serif;
	--bold: 'Urbanist-Bold', sans-serif;
	--commandfont: 'SohneMono', sans-serif;
}

.light-theme {
	--light: #1d1e2a;
	--dark: #f4f6fc;
	--yellow: #7d6c31;
	--gray: #1d1e2a10;
	--grey: #7d6c3130;
	--dark50: #f4f6fc50;
	--code: #1e1e1e;

	--regular: 'Urbanist-Semibold', sans-serif;
	--medium: 'Urbanist-Medium', sans-serif;
	--semibold: 'Urbanist-Semibold', sans-serif;
	--bold: 'Urbanist-Bold', sans-serif;
	--commandfont: 'SohneMono', sans-serif;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar:horizontal {
	height: 10px;
}

::-webkit-scrollbar-track {
	background: #1d1e2a20 !important;
}

::-webkit-scrollbar-thumb {
	background: #555555 !important;
}

::-webkit-scrollbar-thumb:hover {
	background: #1d1e2a !important;
}

.owl-carousel {
	height: fit-content;
	padding: 10px;
	justify-content: center;
	align-items: center;
	margin: auto 5px;
}
.owl-item {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
}
.owl-dots {
	text-align: center;
	margin-top: 20px;
}
.owl-dot {
	height: 13px;
	width: 13px;
	margin: 0 5px;
	outline: none !important;
	border-radius: 50%;
	border: 2px solid var(--yellow) !important;
	transition: all 0.3s ease;
}
.owl-dot.active {
	width: 35px;
	border-radius: 14px;
}
.owl-dot.active,
.owl-dot:hover {
	background: var(--yellow) !important;
}

@media screen and (max-width: 768px) {
	html::-webkit-scrollbar,
	body::-webkit-scrollbar {
		width: 0px;
	}
}

@media screen and (max-width: 480px) {
	html::-webkit-scrollbar,
	body::-webkit-scrollbar {
		width: 0px;
	}
}

[data-title] {
	position: relative;
	display: inline-block;
  }
  
  [data-title]::after {
	content: attr(data-title);
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	padding: 4px 8px;
	background-color: #1e1e1e;
	color: #f4f6fc;
	font-size: 12px;
	border-radius: 4px;
	white-space: nowrap;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  [data-title]:hover::after {
	opacity: 1;
	visibility: visible;
  }
.portfolio-container {
	display: flex;
	flex-direction: column;
	margin: 10px;
	padding-top: 40px;
	position: relative;
	height: fit-content;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 60px;
}

.portfolio-container .text {
	color: var(--yellow);
	font-size: 16px;
	letter-spacing: 1px;
	font-family: var(--semibold);
	text-align: center;
}

.portfolio-container .bold {
	color: var(--light);
	font-size: 36px;
	margin: 30px 0;
	width: 40%;
	text-align: center;
	letter-spacing: 1px;
	font-family: var(--regular);
}

@media screen and (max-width: 1200px) {
	.portfolio-container {
		display: flex;
		flex-direction: column;
		margin: 10px;
		padding-top: 40px;
		position: relative;
		justify-content: flex-start;
		align-items: center;
	}

	.portfolio-container .bold {
		color: var(--light);
		font-size: 36px;
		margin: 30px 0;
		width: 50%;
		text-align: center;
	}
}

@media screen and (max-width: 992px) {
	.portfolio-container {
		display: flex;
		flex-direction: column;
		margin: 10px;
		padding-top: 40px;
		position: relative;
		justify-content: flex-start;
		align-items: center;
	}

	.portfolio-container .bold {
		color: var(--light);
		font-size: 36px;
		margin: 30px 0;
		width: 55%;
		text-align: center;
	}
}

@media screen and (max-width: 768px) {
	.portfolio-container {
		display: flex;
		flex-direction: column;
		margin: 10px;
		position: relative;
		padding-top: 30px;
		padding-bottom: 20px;
		height: fit-content;
		justify-content: flex-start;
		align-items: center;
	}

	.portfolio-container .bold {
		color: var(--light);
		font-size: 32px;
		margin: 20px 0;
		width: 65%;
		text-align: center;
	}
}

@media screen and (max-width: 480px) {
	.portfolio-container {
		display: flex;
		flex-direction: column;
		margin: 10px;
		padding-top: 20px;
		position: relative;
		padding-bottom: 15px;
		height: fit-content;
		justify-content: flex-start;
		align-items: center;
	}

	.portfolio-container .bold {
		color: var(--light);
		font-size: 24px;
		margin: 15px 0;
		width: 85%;
		text-align: center;
	}

	.portfolio-container .text {
		color: var(--yellow);
		font-size: 14px;
		text-align: center;
	}
}

@media screen and (max-width: 320px) {
	.portfolio-container {
		padding-top: 20px;
		padding-bottom: 15px;
		height: fit-content;
	}

	.portfolio-container .bold {
		color: var(--light);
		font-size: 20px;
		margin: 15px 0;
		width: 85%;
		text-align: center;
	}
}

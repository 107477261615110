.blog-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--dark);
	height: fit-content;
	margin-top: 110px;
}

.blog-container .blog-content {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 500px;
	width: 70vw;
	overflow: hidden;
	position: relative;
	background-image: url(../assets/images/dark-header.png);
	background-size: cover;
	background-position: center;
	background-color: var(--dark);
}

.blog-container .blog-content.light {
	background-image: url(../assets/images/light-header.png);
	background-size: cover;
	background-position: center;
	background-color: var(--dark);
}

.blog-container .blog-content .heading {
	position: absolute;
	left: 50px;
	bottom: 50px;
}

.blog-container .blog-content .heading h1 {
	font-size: 48px;
	color: var(--light);
	letter-spacing: 1px;
	font-family: var(--regular);
	text-align: center;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.blog-container .heading .tags {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.blog-container .heading .tags .tag {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--gray);
	color: var(--dark);
	font-size: 14px;
	font-family: var(--regular);
	padding: 5px 10px;
	margin-right: 10px;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.5s ease;
}

.blog-container .heading .tags .tag:hover {
	background-color: var(--light);
	color: var(--dark);
}

.blog-container .heading .tags .tag span {
	margin: 0 5px;
	color: var(--light);
	transition: all 0.5s ease;
}

.blog-container .heading .tags .tag:hover span {
	color: var(--dark);
}

.blog-container .blogs {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 70vw;
	height: fit-content;
	position: relative;
	margin-bottom: 50px;
}

.blog-container .blogs .blog {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	height: fit-content;
	width: 95%;
	margin: 10px 0;
	cursor: pointer;
}

.blog-container .blogs .blog p {
	font-size: 14px;
	color: var(--light);
	font-family: var(--regular);
	letter-spacing: 1px;
	margin: 7px 0;
}

.blog-container .blogs .blog h1 {
	font-size: 20px;
	color: var(--light);
	font-family: var(--regular);
	letter-spacing: 3px;
	margin: 7px 0;
	height: fit-content;
}

.blog-container .blogs .blog .img-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
	width: 300px;
	overflow: hidden;
	margin: 10px 0;
	margin-right: 20px;
	border: 2px solid var(--grey);
	border-radius: 5px;
}

.blog-container .blogs .blog img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 0;
}

.blog-container .blogs .blog-info {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: calc(95% - 300px);
	margin-top: 30px;
}

.blog-container .blogs .blog-info .blog-author-info{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-top: -20px;
}

.blog-container .blogs .blog-info .blog-author-info .writer-img {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	overflow: hidden;
	margin: 10px 0;
	margin-right: 20px;
	border: 2px solid var(--grey);
	border-radius: 50%;
}

@media screen and (max-width: 1200px) {
	.blog-container .blog-content {
		width: 80vw;
	}

	.blog-container .blog-content .heading h1 {
		font-size: 36px;
	}

	.blog-container .blogs {
		width: 80vw;
	}

	.blog-container .blogs .blog h1 {
		font-size: 20px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}
}

@media screen and (max-width: 992px) {
	.blog-container .blog-content {
		width: 90vw;
	}

	.blog-container .blog-content .heading h1 {
		font-size: 32px;
	}

	.blog-container .blogs {
		width: 90vw;
	}

	.blog-container .blogs .blog {
		flex-direction: column-reverse;
	}

	.blog-container .blogs .blog .img-container {
		width: 100%;
		height: 300px;
		margin: 10px 0;
		margin-right: 0;
	}

	.blog-container .blogs .blog-info {
		width: 100%;
	}

	.blog-container .blogs .blog h1 {
		font-size: 20px;
	}
}

@media screen and (max-width: 768px) {
	.blog-container .blog-content {
		width: 90vw;
	}

	.blog-container .blog-content .heading {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		left: 0;
		bottom: 0;
	}

	.blog-container .heading .tags {
		justify-self: center;
		align-items: center;
		flex-direction: column;
	}

	.blog-container .heading .tags .tag {
		padding: 5px 5px;
		margin: 3px 5px;
	}

	.blog-container .blog-content .heading h1 {
		font-size: 28px;
	}

	.blog-container .blogs {
		width: 90vw;
	}

	.blog-container .blogs .blog {
		flex-direction: column-reverse;
	}

	.blog-container .blogs .blog .img-container {
		width: 100%;
		height: 200px;
		margin: 10px 0;
		margin-right: 0;
	}

	.blog-container .blogs .blog-info {
		width: 100%;
	}

	.blog-container .blogs .blog h1 {
		font-size: 18px;
	}
}

@media screen and (max-width: 480px) {
	.blog-container .blog-content .heading {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		left: 0;
		bottom: 0;
	}

	.blog-container .blog-content .heading h1 {
		font-size: 24px;
	}

	.blog-container .heading .tags {
		justify-self: center;
		align-items: center;
		flex-direction: column;
	}

	.blog-container .heading .tags .tag {
		padding: 5px 5px;
		margin: 3px 5px;
	}

	.blog-container .blogs .blog {
		flex-direction: column-reverse;
	}

	.blog-container .blogs .blog .img-container {
		width: 100%;
		margin: 5px 0;
		height: 200px;
		object-position: 0;
	}

	.blog-container .blogs .blog-info {
		width: 100%;
	}

	.blog-container .blogs .blog h1 {
		font-size: 16px;
	}

	.blog-container .blogs .blog p {
		font-size: 14px;
		margin: 5px 0;
	}
}

@media screen and (max-width: 320px) {
	.blog-container .blog-content .heading {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		left: 0;
		bottom: 0;
	}

	.blog-container .blog-content .heading h1 {
		font-size: 20px;
	}

	.blog-container .heading .tags {
		justify-self: center;
		align-items: center;
	}

	.blog-container .heading .tags .tag {
		padding: 3px 5px;
		margin: 0 5px;
	}

	.blog-container .blogs .blog h1 {
		font-size: 14px;
	}
}

.vibes-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #F4F5F9;
	height: fit-content;
}

.vibes-container .terms-content {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 500px;
	width: 75vw;
	overflow: hidden;
	position: relative;
	background-color: #F4F5F9;
}

.vibes-container .terms-content .heading {
	position: absolute;
	left: 50px;
	bottom: 100px;
}

.vibes-container .terms-content .heading h1 {
	font-size: 48px;
	color: #0E0D0D;
	letter-spacing: 1px;
	font-family: var(--regular);
	text-align: center;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.vibes-container .terms-content .heading p {
	font-size: 16px;
	color: #0E0D0D;
	letter-spacing: 1px;
	font-family: var(--regular);
	text-align: center;
}

.vibes-container .main-content {
	display: flex;
	flex-wrap: wrap;
	gap: 25px;
	width: 75vw;
}

.vibes-container .main-content .content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 22vw;
	height: fit-content;
	margin-bottom: 50px;
}

.vibes-container .main-content .content h2 {
	font-size: 18px;
	color: #0E0D0D;
	letter-spacing: 1px;
	font-family: var(--regular);
	text-align: left;
	margin-bottom: 10px;
	text-transform: uppercase;
}

.vibes-container .main-content .content h6 {
	font-size: 16px;
	color: #0E0D0D;
	letter-spacing: 1px;
	font-family: var(--regular);
	text-align: left;
	margin-bottom: 10px;
	line-height: 1.5;
}

.vibes-container .main-content .content p {
	font-size: 16px;
	color: #0E0D0D;
	letter-spacing: 1px;
	font-family: var(--regular);
	text-align: justify;
	line-height: 1.5;
}

.vibes-container .main-content .content p b {
	font-size: 16px;
	color: #D71B1B;
	letter-spacing: 1px;
	font-family: var(--regular);
	text-align: left;
	line-height: 1.5;
}

.vibes-container .main-content .content ol li ,
.vibes-container .main-content .content ul li {
	font-size: 16px;
	color: #0E0D0D;
	letter-spacing: 1px;
	font-family: var(--regular);
	text-align: left;
	line-height: 2;
}

.vibes-container .main-content .content ol li  b ,
.vibes-container .main-content .content ul li  b {
	font-size: 16px;
	color: #D71B1B;
}

@media screen and (max-width: 1200px) {
	.vibes-container .terms-content {
		width: 85vw;
	}

	.vibes-container .terms-content .heading h1 {
		font-size: 36px;
	}

	.vibes-container .main-content {
		width: 85vw;
	}

	.vibes-container .main-content .content {
		width: 25vw;
	}
}

@media screen and (max-width: 992px) {
	.vibes-container .terms-content {
		width: 85vw;
	}

	.vibes-container .terms-content .heading h1 {
		font-size: 32px;
	}

	.vibes-container .main-content {
		width: 85vw;
	}

	.vibes-container .main-content .content {
		width: 40vw;
	}
}

@media screen and (max-width: 768px) {
	.vibes-container .terms-content {
		width: 90vw;
	}

	.vibes-container .terms-content .heading {
		position: absolute;
		left: 10px;
		bottom: 100px;
	}

	.vibes-container .terms-content .heading h1 {
		font-size: 28px;
	}

	.vibes-container .terms-content .heading p {
		font-size: 16px;
	}

	.vibes-container .main-content {
		width: 90vw;
	}

	.vibes-container .main-content .content {
		width: 42vw;
	}

	.vibes-container .main-content .content h2 {
		font-size: 16px;
	}
}

@media screen and (max-width: 480px) {
	.vibes-container .terms-content {
		width: 90vw;
	}

	.vibes-container .terms-content .heading {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-self: center;
		align-items: center;
		bottom: 0;
		left: 0;
	}

	.vibes-container .terms-content .heading h1 {
		font-size: 24px;
	}

	.vibes-container .terms-content .heading p {
		font-size: 16px;
	}

	.vibes-container .main-content {
		width: 90vw;
	}

	.vibes-container .main-content .content {
		justify-content: center;
		align-items: center;
		width: 85vw;
	}

	.vibes-container .main-content .content h2 {
		font-size: 16px;
	}

	.vibes-container .main-content .content p {
		text-align: left;
	}
}

@media screen and (max-width: 320px) {
	.vibes-container .terms-content .heading {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-self: center;
		align-items: center;
	}
	.vibes-container .terms-content .heading h1 {
		font-size: 20px;
	}
}

.about-container {
	display: flex;
	flex-direction: column;
	margin: 10px 0 auto 0;
	padding-top: 40px;
	position: relative;
	background-color: var(--gray);
	height: fit-content;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 60px;
}

.about-container .text {
	color: var(--yellow);
	font-size: 16px;
	font-family: var(--semibold);
	text-align: center;
	letter-spacing: 1px;
}

.about-container .bold {
	color: var(--light);
	font-size: 36px;
	margin: 30px 0;
	width: 40%;
	text-align: center;
	letter-spacing: 1px;
	font-family: var(--regular);
}

.about-container .flex {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.about-container .imageContainer {
	margin: 0 20px 0 10px;
	width: 26vw;
	height: 26vw;
	overflow: hidden;
	border-radius: 5px;
}

.about-container .image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.about-container .paragraph {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 40vw;
	margin: auto 25px;
}

.about-container .heading {
	font-size: 36px;
	text-align: center;
	margin: auto auto 20px auto;
	letter-spacing: 1px;
	font-family: var(--regular);
}


.about-container .paragraph p {
	color: var(--light);
	font-size: 16px;
	text-align: justify;
	font-family: var(--regular);
	line-height: 1.5;
}

@media screen and (max-width: 1200px) {
	.about-container .imageContainer {
		margin: 0 20px;
		width: 35vw;
		height: 35vw;
	}

	.about-container .paragraph {
		width: 45vw;
		margin: auto 25px;
	}
}

@media screen and (max-width: 992px) {
	.about-container .bold {
		color: var(--light);
		font-size: 36px;
		margin: 30px 0;
		width: 55%;
		text-align: center;
	}

	.about-container .imageContainer {
		margin: 0 10px;
		width: 35vw;
		height: 35vw;
	}

	.about-container .paragraph {
		width: 55vw;
		margin: auto 15px;
	}
}

@media screen and (max-width: 768px) {
	.about-container {
		padding-top: 30px;
		padding-bottom: 40px;
		height: fit-content;
	}

	.about-container .flex {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.about-container .bold {
		color: var(--light);
		font-size: 32px;
		margin: 20px 0;
		width: 65%;
		text-align: center;
	}

	.about-container .heading {
		font-size: 24px;
		text-align: center;
		margin: auto auto 20px auto;
	}

	.about-container .imageContainer {
		margin: 0 10px 20px 10px;
		width: 60vw;
		height: 60vw;
	}

	.about-container .paragraph {
		width: 80vw;
		margin: auto 15px;
	}
}

@media screen and (max-width: 480px) {
	.about-container {
		padding-top: 20px;
		padding-bottom: 25px;
		height: fit-content;
	}
	.about-container .text {
		color: var(--yellow);
		font-size: 14px;
		font-family: var(--semibold);
		text-align: center;
	}

	.about-container .bold {
		color: var(--light);
		font-size: 24px;
		margin: 15px 0;
		width: 85%;
		text-align: center;
	}

	.about-container .heading {
		font-size: 24px;
		text-align: center;
		margin: auto auto 15px auto;
	}

	.about-container .imageContainer {
		margin: 0 10px 20px 10px;
		width: 70vw;
		height: 70vw;
	}

	.about-container .paragraph {
		width: 85vw;
		margin: auto 10px;
	}

	.about-container .paragraph p {
		text-align: center;
	}
}

@media screen and (max-width: 320px) {
	.about-container {
		padding-top: 20px;
		padding-bottom: 25px;
		height: fit-content;
	}

	.about-container .bold {
		color: var(--light);
		font-size: 20px;
		margin: 15px 0;
		width: 90%;
		text-align: center;
	}

	.about-container .heading {
		font-size: 30px;
		text-align: center;
		margin: auto auto 15px auto;
	}

	.about-container .imageContainer {
		margin: 0 10px 20px 10px;
		width: 70vw;
		height: 70vw;
	}

	.about-container .paragraph {
		width: 85vw;
		margin: auto 10px;
	}
}
